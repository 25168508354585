<template >
  <div class="table-container">
    <!-- <div class="table-tabs">
      <a-radio-group size="large" button-style="solid" v-model:value="filter.is_disposal" @change="handleSearch">
        <a-radio-button value="0">未整理</a-radio-button>
        <a-radio-button value="1">已整理</a-radio-button>
      </a-radio-group>
    </div> -->
    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>公示管理</span>
          <!-- <span class="desc">(一级)</span> -->
        </div>
      </div>
      <div class="filters">
        <a-cascader class="filter" placeholder="区域" :options="areas" v-model:value="ssqy"></a-cascader>
        <a-select class="filter" allowClear placeholder="选择部门" v-model:value="filter.dept_id"
          :options="deptList"></a-select>
        <a-select class="filter" allowClear placeholder="政策类型" v-model:value="filter.zclx"
          :options="zclxList"></a-select>
        <a-input class="filter" placeholder="标题" v-model:value="filter.title"></a-input>
        <a-button class="filter-btn" type="primary" @click="handleSearch">搜索</a-button>
      </div>
      <!-- <div class="opts">
        <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
      </div> -->
    </div>
    <div class="table">
      <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'icon'">
            <div>
              <FjList :fjlist="record.icon" />
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'title'">
            <div class="data-title" @click="toUrl(record)">
              <a-tooltip placement="topLeft" :title="record.title" :color="'geekblue'">
                <span>{{ record.title }}</span>
              </a-tooltip>
            </div>
          </template>

          <template v-if="column.dataIndex == 'dept_id'">
            <div>
              <span>{{ record.dept_info?.dept_name }}</span>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <div class="operations">
              <a class="btn" @click="showZy(record)">摘要</a>

            </div>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal :loading="flags.confirmLoading" v-model:visible="flags.zyVisible" title="提交摘要" @ok="handleOk">
      <a-form layout="vertical">
        <a-form-item label="申报条件">
          <a-textarea v-model:value="currentZy.sbtj" placeholder="请输入" :auto-size="{ minRows: 3 }" />
        </a-form-item>
        <a-form-item label="扶持标准">
          <a-textarea v-model:value="currentZy.fcbz" placeholder="请输入" :auto-size="{ minRows: 3 }" />
        </a-form-item>
        <a-form-item label="申报材料">
          <a-textarea v-model:value="currentZy.sbcl" placeholder="请输入" :auto-size="{ minRows: 3 }" />
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { message, Modal } from 'ant-design-vue';
import FjList from '@/components/common/fjList.vue';
import options from '@/util/address-options';

export default defineComponent({
  components: {
    FjList,
  },

  data() {
    return {
      currentTab: '',
      ssqy: '',
      deptList: [],
      areas: options,
      zclxList:[{value: '立项公示',label: '立项公示',},{value: '获批名单',label: '获批名单',},{value: '奖励文件',label: '奖励文件',},{value: '政策咨询',label: '政策咨询',}],
      filter: {
        ...this.initFilter,
        title: '',
        dept_id: undefined,
        zclx: undefined,
      },
      current: null,
      flags: {
        zyVisible: false,
        confirmLoading: false,
      },
      currentZy: {
        sbtj: '',
        fcbz: '',
        sbcl: '',
        id: '',
      }
    }
  },
  created() {
    this.getOptions();
  },
  methods: {
    async handleOk() {
      if (!this.currentZy.sbtj || !this.currentZy.fcbz || !this.currentZy.sbcl) {
        message.error('请输入完整')
        return;
      }
      this.flags.confirmLoading = true;
      const { code, msg } = await service.submit_article_info({ ...this.currentZy });
      this.flags.confirmLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.flags.zyVisible = false;
        this.handleSearch();
        return true; 
      }
      else {
        message.error(msg || '失败');
        return false;
      }
    },
    showZy(item) {
      // console.log(item);

      this.currentZy = {
        sbtj: item.sbtj || '',
        fcbz: item.fcbz || '',
        sbcl: item.sbcl || '',
        id: item.id,
      };
      this.flags.zyVisible = true;
      // console.log(this.flags.zyVisible);
    },
    async getOptions() {
      const { data: depts } = await service.get_dept_lists({ limit: 1000 });
      this.deptList = depts.map((m) => ({ label: m.dept_name, value: Number(m.id) }));
    },
    // 切换页码调用获取
    handleTableChange(pag, filter, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filter,
      });
    },
    toUrl(item) {
      window.open(item.url)
    },
    // 筛选
    handleSearch() {
      let ssqyTemp = {};
      if (this.ssqy?.length) {
        ssqyTemp['sheng'] = this.ssqy[0]?.split('/')[1] || '';
        ssqyTemp['shi'] = this.ssqy[1]?.split('/')[1] || '';
        ssqyTemp['qu'] = this.ssqy[2]?.split('/')[1] || '';
      }
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter,
        ...ssqyTemp,
      })
    },
    edit(item) {
      this.current = item;
      this.$refs['editRef'].onShow();
    },
    handleMatch(item) {
      Modal.confirm({
        title: '警告',
        content: `确认对[${item.title}]执行匹配?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.match_company({ article_id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    toCreate() {
      this.current = { pid: '0' }
      this.$refs['editRef'].onShow();
    },
  },
  setup() {
    // 声明并调用一次数据拉取
    var initFilter = {
      is_disposal: '0'
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination((params) => service.get_disposal_lists({ ...initFilter, ...params }), {
      formatResult: res => {
        res.data.total_num = res.total_number;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      initFilter,
      dataSource,
      pagination,
      loading,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: '发布日期',
          dataIndex: 'release_time',
        },
        {
          title: '部门',
          dataIndex: 'dept_id',
        },
        {
          title: '政策类型',
          dataIndex: 'zclx',
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 500,
        },
        {
          title: '操作',
          dataIndex: 'operation'
        }
      ],
      run,
    };
  },
})
</script>
<style lang="less" scoped>
.table-container {
  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }
}
</style>